<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="사보 당근농장 수정">
            - 첨부파일은 각각 최대 2MB까지 파일을 올리실 수 있습니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 발행년도</th>
                                <td>
                                    <select v-model="bbs.year" class="w-100px" >
                                        <option v-for="year in bbs.years" :key="year" :value="year">{{year}}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 발행월</th>
                                <td>
                                    <select v-model="bbs.month" class="w-100px" >
                                        <option :value="m" v-for="m in bbs.months" :key="m" >{{m}}월</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                              <th><span class="txt-red">*</span>제목</th>
                              <td>
                                <input type="text" v-model="bbs.title" class="w-100per">
                              </td>
                            </tr>
                            <tr>
                              <th><span class="txt-red">*</span> 미리보기</th>
                              <td>
                                <input type="text" v-model="bbs.preview" class="w-100per">
                              </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span>썸네일</th>
                                <td>
                                    <a v-if="bbs.file1_name" :href="bbs.file1_url" class="btn-view" target="_blank">{{ bbs.file1_name }}</a>
                                    <label v-if="bbs.file1_name" class="ml-10"><input type="checkbox" v-model="bbs.dchk1"> 삭제</label>
                                    <CarrotFileUploader ref="file1"></CarrotFileUploader>
                                </td>
                            </tr>
                            <tr v-for="(irow, i) in bbs.indexs" :key="i" >
                                <th><span class="txt-red">*</span>인덱스 제목{{i+1}}</th>
                                <td>                                    
                                    <input type="text" class="w-100per mb-10" v-model="bbs.indexs[i].index_title">

                                    <a v-if="bbs.indexs[i].index1_name" :href="bbs.indexs[i].index1_url" class="btn-view" target="_blank">{{ bbs.indexs[i].index1_name }}</a>
                                    <label v-if="bbs.indexs[i].index1_name" class="ml-10"><input type="checkbox" v-model="bbs.indexs[i].index1_dchk"> 삭제</label>
                                    <CarrotFileUploader ref="iFile1" :style="[bbs.indexs[i].index1_name ? 'float:left' : '' ]"></CarrotFileUploader>
                                    <div v-html="description"></div>

                                    <a v-if="bbs.indexs[i].index2_name" :href="bbs.indexs[i].index2_url" class="btn-view" target="_blank">{{ bbs.indexs[i].index2_name }}</a>
                                    <label v-if="bbs.indexs[i].index2_name" class="ml-10"><input type="checkbox" v-model="bbs.indexs[i].index2_dchk"> 삭제</label>
                                    <CarrotFileUploader ref="iFile2" :style="[bbs.indexs[i].index2_name ? 'float:left' : '' ]"></CarrotFileUploader>
                                    <div v-html="description"></div>

                                    <a v-if="bbs.indexs[i].index3_name" :href="bbs.indexs[i].index3_url" class="btn-view" target="_blank">{{ bbs.indexs[i].index3_name }}</a>
                                    <label v-if="bbs.indexs[i].index3_name" class="ml-10"><input type="checkbox" v-model="bbs.indexs[i].index3_dchk"> 삭제</label>
                                    <CarrotFileUploader ref="iFile3" :style="[bbs.indexs[i].index3_name ? 'float:left' : '' ]"></CarrotFileUploader>
                                    <div v-html="description"></div>

                                    <a v-if="bbs.indexs[i].index4_name" :href="bbs.indexs[i].index4_url" class="btn-view" target="_blank">{{ bbs.indexs[i].index4_name }}</a>
                                    <label v-if="bbs.indexs[i].index4_name" class="ml-10"><input type="checkbox" v-model="bbs.indexs[i].index4_dchk"> 삭제</label>
                                    <CarrotFileUploader ref="iFile4" :style="[bbs.indexs[i].index4_name ? 'float:left' : '' ]"></CarrotFileUploader>
                                    <div v-html="description"></div>

                                    <a v-if="bbs.indexs[i].index5_name" :href="bbs.indexs[i].index5_url" class="btn-view" target="_blank">{{ bbs.indexs[i].index5_name }}</a>
                                    <label v-if="bbs.indexs[i].index5_name" class="ml-10"><input type="checkbox" v-model="bbs.indexs[i].index5_dchk"> 삭제</label>
                                    <CarrotFileUploader ref="iFile5" :style="[bbs.indexs[i].index5_name ? 'float:left' : '' ]"></CarrotFileUploader>
                                    <div v-html="description"></div>

                                    <a v-if="bbs.indexs[i].index6_name" :href="bbs.indexs[i].index6_url" class="btn-view" target="_blank">{{ bbs.indexs[i].index6_name }}</a>
                                    <label v-if="bbs.indexs[i].index6_name" class="ml-10"><input type="checkbox" v-model="bbs.indexs[i].index6_dchk"> 삭제</label>
                                    <CarrotFileUploader ref="iFile6" :style="[bbs.indexs[i].index6_name ? 'float:left' : '' ]"></CarrotFileUploader>
                                    <div v-html="description"></div>

                                    <a v-if="bbs.indexs[i].index7_name" :href="bbs.indexs[i].index7_url" class="btn-view" target="_blank">{{ bbs.indexs[i].index7_name }}</a>
                                    <label v-if="bbs.indexs[i].index7_name" class="ml-10"><input type="checkbox" v-model="bbs.indexs[i].index7_dchk"> 삭제</label>
                                    <CarrotFileUploader ref="iFile7" :style="[bbs.indexs[i].index7_name ? 'float:left' : '' ]"></CarrotFileUploader>
                                    <div v-html="description"></div>

                                    <a v-if="bbs.indexs[i].index8_name" :href="bbs.indexs[i].index8_url" class="btn-view" target="_blank">{{ bbs.indexs[i].index8_name }}</a>
                                    <label v-if="bbs.indexs[i].index8_name" class="ml-10"><input type="checkbox" v-model="bbs.indexs[i].index8_dchk"> 삭제</label>
                                    <CarrotFileUploader ref="iFile8" :style="[bbs.indexs[i].index8_name ? 'float:left' : '' ]"></CarrotFileUploader>
                                    <div v-html="description"></div>


                                    <button v-if="i==0" @click="bbs.addIndexs()" class="btn-default float-right">추가</button>
                                    <label v-else-if="bbs.indexs[i].is_update" class="ml-10 float-right"><input type="checkbox" v-model="bbs.indexs[i].index_allDchk"> 삭제</label>
                                    <button v-else @click="bbs.delIndexs(i)" class="btn-default w-65px float-right">삭제</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn-default float-left mt-30" @click="bbs.showList">목록</button>
                    <button class="btn-default float-right mt-30" @click="bbs.onSave">수정하기</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import { join } from 'path';
//import CarrotEditor from '@/components/common/CarrotEditor.vue'
//import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'

export default {
    layout:"ResourceManagement",
    components: {
      //CarrotDatePicker,        
      // CarrotEditor
    },
    setup() {
        const router = useRouter();
        const route  = useRoute();
        const toast = useToast();
        const file1 = ref(null);
        
        const iFile1 = ref([]);
        const iFile2 = ref([]);
        const iFile3 = ref([]);
        const iFile4 = ref([]);
        const iFile5 = ref([]);
        const iFile6 = ref([]);
        const iFile7 = ref([]);
        const iFile8 = ref([]);
        const iFile9 = ref([]);
        const iFile10 = ref([]);
        const iFile11 = ref([]);
        const iFile12 = ref([]);
        const iFile13 = ref([]);
        const iFile14 = ref([]);
        const iFile15 = ref([]);
        const bbs = reactive({
            years : [],
            months : [],
            year : "",
            month : "",
            title : "",
            preview : "",
            index_length : 15,
            index_file_length : 8,
            indexs:[],
            file1_name : "", file1_url : "",
            dchk1 : false, 

            showList: () => {
                router.push({ name:"ResourceManagement-NewsLetterManagementList" });
            },
            addIndexs : () => {
                if(bbs.indexs.length < bbs.index_length) {
                    bbs.indexs.push({ index_title : '', index_file: [], is_update : false});
                } else {
                    toast.error(`최대 추가 갯수는 ${bbs.index_length}개 입니다. 추가를 원하시면 문의 주세요`);
                }
            },
            delIndexs : (i) => {
                bbs.indexs.splice(i, 1);
            },
            getInfo: () => {
                let params = {
                    idx : bbs.idx
                };
                axios.get("/rest/resourceManagement/newsLetterView", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.year   = res.data.year;
                        bbs.month   = res.data.month;
                        bbs.title   = res.data.title;
                        bbs.preview   = res.data.preview;
                        bbs.file1_name = res.data.file1_name;
                        bbs.file1_url  = res.data.file1_url;
                        
                        for( let [i,idx_list] of res.data.idx_list.entries()) {
                            bbs.indexs[i] = {
                                is_update: true, 
                                index_allDchk : false, //전체 삭제
                                index_title: idx_list.index_title, 

                                index1_file: idx_list.index1_file, index1_name: idx_list.index1_name,
                                index1_url: idx_list.index1_url, index1_dchk: false,
                                
                                index2_file: idx_list.index2_file, index2_name: idx_list.index2_name,
                                index2_url: idx_list.index2_url, index2_dchk: false,

                                index3_file: idx_list.index3_file, index3_name: idx_list.index3_name,
                                index3_url: idx_list.index3_url, index3_dchk: false,

                                index4_file: idx_list.index4_file, index4_name: idx_list.index4_name,
                                index4_url: idx_list.index4_url, index4_dchk: false,

                                index5_file: idx_list.index5_file, index5_name: idx_list.index5_name,
                                index5_url: idx_list.index5_url, index5_dchk: false,

                                index6_file: idx_list.index6_file, index6_name: idx_list.index6_name,
                                index6_url: idx_list.index6_url, index6_dchk: false,

                                index7_file: idx_list.index7_file, index7_name: idx_list.index7_name,
                                index7_url: idx_list.index7_url, index7_dchk: false,

                                index8_file: idx_list.index8_file, index8_name: idx_list.index8_name,
                                index8_url: idx_list.index8_url, index8_dchk: false,

                            }
                        }
                        
                     
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            onSave: () => {
                

                if( !bbs.year ){
                    toast.error("발행년도를 입력하세요.");
                    return;
                }
                if( !bbs.month ){
                    toast.error("발행월을 입력하세요.");
                    return;
                }
                if( !bbs.title ){
                    toast.error("제목을 입력하세요.");
                    return;
                }
                if( !bbs.preview ){
                  toast.error("미리보기를 입력하세요.");
                  return;
                }

                if( !bbs.file1_url && file1.value.file.filedata===null) {
                    toast.error("썸네일을 등록하세요.");
                    return;
                }
                if( bbs.dchk1===true && file1.value.file.filedata===null) {
                    toast.error("썸네일을 등록하세요.");
                    return;   
                }

                for(var i in bbs.indexs) {
                    if(!bbs.indexs[i].index_title) {
                        toast.error("인덱스 제목을 입력하세요.");
                        return;
                    }
                }
                var formData = new FormData();
                formData.append("idx", bbs.idx);
                formData.append("year", bbs.year);
                formData.append("month", bbs.month);
                formData.append("title", bbs.title);
                formData.append("preview", bbs.preview);
                formData.append("dchk1", bbs.dchk1==true?"Y":"N");

                if(file1.value.file.filedata!==null) {
                    formData.append("nfile1", file1.value.file.filedata);
                }
                
                for(i in bbs.indexs) {
                    formData.append("index_title[]", bbs.indexs[i].index_title);
                    formData.append("index_allDchk[]", bbs.indexs[i].index_allDchk===true?'Y':'N');

                    formData.append(`index_dchk[${i}][]`, bbs.indexs[i].index1_dchk===true?'Y':'N');
                    formData.append(`index_dchk[${i}][]`, bbs.indexs[i].index2_dchk===true?'Y':'N');
                    formData.append(`index_dchk[${i}][]`, bbs.indexs[i].index3_dchk===true?'Y':'N');
                    formData.append(`index_dchk[${i}][]`, bbs.indexs[i].index4_dchk===true?'Y':'N');
                    formData.append(`index_dchk[${i}][]`, bbs.indexs[i].index5_dchk===true?'Y':'N');
                    formData.append(`index_dchk[${i}][]`, bbs.indexs[i].index6_dchk===true?'Y':'N');
                    formData.append(`index_dchk[${i}][]`, bbs.indexs[i].index7_dchk===true?'Y':'N');
                    formData.append(`index_dchk[${i}][]`, bbs.indexs[i].index8_dchk===true?'Y':'N');
                    
                }

                //인덱스 제목 파일
                for(i=1; i <= bbs.index_length; i++) {
                    var iFile = eval(new String(`iFile${i}`).toString());

                    //iFile가 Add와 다르게 사용되어  bbs.index_length-1로 적용
                    for(var j=0; j <= bbs.index_length-1; j++) {
                        if(iFile.value[j]?.file.filedata) {
                            formData.append(`index_files${i}_${j+1}`, iFile.value[j].file.filedata);
                        }
                    }
                }
                if(!confirm("수정하시겠습니까?")) return;

                axios.post('/rest/resourceManagement/newsLetterMod', formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                }).then((res)=>{
                    if( res.data.err == 0 ){
                        router.push({ 
                            name:"ResourceManagement-NewsLetterManagementView-idx",
                            params : { idx:bbs.idx }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            }
        });
        

        onMounted(() => {
            // Mounted
            bbs.idx = route.params.idx;
            if ( !bbs.idx ) {
                router.back(-1);
            }

            bbs.years = [];
            let ndate = new Date();
            let syear = ndate.getFullYear()-16;
            let eyear = ndate.getFullYear()+10;
            
            for(let i=syear;i<=eyear;i++) {
                bbs.years.push(i);
            }
            for(let i=1;i<=12;i++) {
                i = i < 10 ? join('0')+i : i;
                bbs.months.push(i);
            }


            bbs.getInfo();

        });

        return {bbs,file1,iFile1,iFile2,iFile3,iFile4,iFile5,iFile6,iFile7,iFile8,iFile9,iFile10,iFile11,iFile12,iFile13,iFile14,iFile15};
    }
}
</script>

<style lang="scss" scoped>
</style>